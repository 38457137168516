<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Card Configs Table</h6>
      <button @click="openModal('add')" class="btn btn-primary mb-3">Add New Config</button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">STT</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Image</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Coin/Card ID</th>

              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Quantity</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Number</th>

              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredData" :key="item._id">
              <td>{{ index + 1 }}</td>
              <td>
                <img :src="item.id_card?.image[0]" alt="Card Image" style="width: 50px; height: 50px;"
                  v-if="item.id_card && item.id_card?.image[0]">
              </td>
              <td>{{ item.id_card?._id || item.id_coin?._id || 'Unknown' }}</td>
              <td>{{ formatNumber(item.quantity) }}</td>

              <td>{{ formatNumber(item.number) }}</td>
              <td>
                <button @click="openModal('edit', item)" class="btn btn-warning me-2">Edit</button>
                <button @click="deleteCard(item._id)" class=" btn btn-danger">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal for Adding or Editing a Card -->
    <div v-if="showModal" class="modal show" tabindex="-1" role="dialog"
      style="display: block; background-color: rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isEditing ? 'Edit Config' : 'Add New Config' }}</h5>
            <button type="button" class="close" @click="closeModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="handleSave">
              <div class="form-group">
                <label>Card ID</label>
                <input v-model="formData.id_card" type="text" class="form-control" required />
              </div>
              <div class="form-group">
                <label>Number</label>
                <input v-model="formData.number" type="number" class="form-control" required />
              </div>
              <button type="submit" class="btn btn-primary">{{ isEditing ? 'Update' : 'Create' }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axiosInstance from '@/utils/axios';

// State variables for cards data, modal visibility, etc.
const cards = ref([]);
const coins = ref([]);
const filteredData = ref([]);
const showModal = ref(false);
const isEditing = ref(false);
const currentCardId = ref(null);

// Form data used for both Add and Edit operations
const formData = ref({
  id_card: '',
  number: 0,
});

// Format numbers for displaying
const formatNumber = (value) => {
  return value !== undefined && value !== null ? value : 0;
};

// Fetch all card data from API
const fetchCards = async () => {
  try {
    const responseCards = await axiosInstance.get('/api/all/card/buy');
    const responseCoins = await axiosInstance.get('/api/all/card');
    cards.value = responseCards.data.data;
    coins.value = responseCoins.data.data;
console.log(responseCards,"buy")
console.log(responseCoins,"card")
    // Merge and filter data
    mergeData();
  } catch (error) {
    console.error('Error fetching card configs:', error);
  }
};

// Function to merge and filter data by matching or non-matching IDs
const mergeData = () => {
  const merged = [];

  // Find cards with matching coins
  cards.value.forEach((card) => {
    const matchingCoin = coins.value.find((coin) => coin._id === card.id_card?._id);
    if (matchingCoin) {
      merged.push({
        ...card,
        quantity: matchingCoin.quantity,
      });
    } else {
      merged.push({ ...card, quantity: 0 });
    }
  });

  // Add coins without matching cards
  coins.value.forEach((coin) => {
    const matchingCard = cards.value.find((card) => card.id_card?._id === coin._id);
    if (!matchingCard) {
      merged.push({ ...coin, number: 0 });
    }
  });

  filteredData.value = merged;
};

// Open modal for adding or editing a card configuration
const openModal = (mode, item = null) => {
  showModal.value = true;
  if (mode === 'edit' && item) {
    isEditing.value = true;
    currentCardId.value = item._id;
    formData.value = {
      id_card: item.id_card?._id || '',
      number: item.number || 0,
    };
  } else {
    isEditing.value = false;
    resetForm();
  }
};

const closeModal = () => {
  showModal.value = false;
  resetForm();
};

const handleSave = async () => {
  try {
    const cardData = {
      id_card: formData.value.id_card,
      number: formData.value.number,
    };

    if (isEditing.value) {
      await axiosInstance.put(`/api/update/card/buy/${currentCardId.value}`, cardData);
    } else {
      await axiosInstance.post('/api/save/card/buy', cardData);
    }

    fetchCards();
    closeModal();
  } catch (error) {
    console.error('Error saving card config:', error.response ? error.response.data : error.message);
  }
};

const deleteCard = async (cardId) => {
  try {
    await axiosInstance.delete(`/api/delete/card/buy/${cardId}`);
    fetchCards();
  } catch (error) {
    console.error('Error deleting card config:', error);
  }
};

// Reset the form to its initial state
const resetForm = () => {
  formData.value = {
    id_card: '',
    number: 0,
  };
  currentCardId.value = null;
  isEditing.value = false;
};

// Fetch card data on component mount
onMounted(fetchCards);
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal .modal-dialog {
  margin: 10% auto;
}
</style>
