<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Price Configs Table</h6>
      <button @click="openModal('add')" class="btn btn-primary mb-3">Add New Config</button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">STT</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Number Spin</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Price</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(card, index) in cards" :key="card._id">
              <td>{{ index + 1 }}</td>
              <td>{{ formatNumber(card.numberSpin) }}</td>
              <td>{{ formatNumber(card.price) }}</td>
              <td>
                <button @click="openModal('edit', card)" class="btn btn-warning me-2">Edit</button>
                <button @click="deleteCard(card._id) " class="d-none btn btn-danger">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="showModal" class="modal show" tabindex="-1" role="dialog" style="display: block; background-color: rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isEditing ? 'Edit Config' : 'Add New Config' }}</h5>
            <button type="button" class="close" @click="closeModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="handleSave">
              <div class="form-group">
                <label>Number of Spins</label>
                <input v-model="formData.numberSpin" type="number" class="form-control" required />
              </div>
              <div class="form-group">
                <label>Price</label>
                <input v-model="formData.price" type="number" class="form-control" step="0.01" required />
              </div>
              <div class="form-group">
                <label>Id Coin</label>
                <!-- <input v-model="formData.id_coin" type="text" class="form-control" required /> -->
                 <select v-model="formData.id_coin" class="form-control" required>
      <option v-for="coin in coins" :key="coin._id" :value="coin._id">
        {{ coin.name }}
      </option>
    </select>
              </div>
              <button type="submit" class="btn btn-primary">{{ isEditing ? 'Update' : 'Create' }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axiosInstance from '@/utils/axios';

const cards = ref([]);
const coins = ref([]);
const showModal = ref(false);
const isEditing = ref(false);
const currentCardId = ref(null);
const formData = ref({
  numberSpin: 0,
  price: 0,
  id_coin: '',   
});

const formatNumber = (value) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value);
};
 
const fetchCards = async () => {
  try {
    const response = await axiosInstance.get('/api/all/buyConfig');
    console.log(response,
      "lucky"
    )

    cards.value = response.data.data;  
    const responsecoin = await axiosInstance.get('/api/all/coin');
    coins.value = responsecoin.data.data;
 
  } catch (error) {
    console.error('Error fetching card configs:', error);
  }
};

const openModal = (mode, card = null) => {
  showModal.value = true;
  if (mode === 'edit' && card) {
    isEditing.value = true;
    currentCardId.value = card._id;   
    formData.value = {
      numberSpin: card.numberSpin,
      price: card.price,
      id_coin: card.id_coin || '',  
    };
  } else {
    isEditing.value = false;
    resetForm();
  }
};


 
const closeModal = () => {
  showModal.value = false;
  resetForm();
};

const handleSave = async () => {
  try {
    console.log('Saving card...');

    const cardData = {
      numberSpin: formData.value.numberSpin,
      price: formData.value.price,
      id_coin: formData.value.id_coin
    };

    console.log('Card data:', cardData);

    if (isEditing.value) {
      console.log('Editing card with ID:', currentCardId.value);

  
      await axiosInstance.put(`/api/update/buy/config/${currentCardId.value}`, cardData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } else {
      console.log('Creating new card...');
 
      await axiosInstance.post('/api/save/buy/config', cardData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    console.log('Card saved successfully');
    fetchCards();  
    closeModal();  

  } catch (error) {
    console.error('Error saving card config:', error.response ? error.response.data : error.message);
  }
};


 
const deleteCard = async (cardId) => {
  try {
    await axiosInstance.delete(`/api/delete/buy/config/${cardId}`);
    fetchCards();
  } catch (error) {
    console.error('Error deleting card config:', error);
  }
};

 
const resetForm = () => {
  formData.value = {
    id_coin: '',  
    numberSpin: 0,
    price: 0,
  };
  currentCardId.value = null;
  isEditing.value = false;
};

onMounted(fetchCards);
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal .modal-dialog {
  margin: 10% auto;
}
</style>
