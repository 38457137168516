<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Card Configs Table</h6>
      <button @click="openModal('add')" class="btn btn-primary mb-3">Add New Card</button>
      <!-- Filter Dropdown -->
      <div class="filter-section  ">
        <label for="filterStatus">Filter by Is Winner:</label>
        <select v-model="filterStatus" id="filterStatus" class="form-select">
          <option value="all">All</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">STT</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Image</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ID</th>

              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Coin Name</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Quantity</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Number of Wins</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Remaining</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Is Winner</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr v-for="(card, index) in cards" :key="card._id"> -->
               <tr v-for="(card, index) in filteredCards" :key="card._id"> 
              <td>{{ index + 1 }}</td>
              <td>
                <img :src="card.image[0]" alt="Card Image" style="width: 50px; height: 50px;"
                  v-if="card.image && card.image[0]">
              </td>
              <td>{{ card._id || 'Unknown' }}</td>
              <td>{{ card.id_coin?.name || 'Unknown' }}</td>



              <td>{{ formatNumber(card.quantity) }}</td>
              <td>{{ formatNumber(card.numberWins) }}</td>
              <td>{{ formatNumber(card.remaining) }}</td>
              <td>{{ card.isWinner ? 'Yes' : 'No' }}</td>
              <td>
                <button @click="openModal('edit', card)" class="btn btn-warning me-2">Edit</button>
                <button @click="deleteCard(card._id)" class=" btn btn-danger">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal for Adding or Editing a Card -->
    <div v-if="showModal" class="modal show" tabindex="-1" role="dialog"
      style="display: block; background-color: rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isEditing ? 'Edit Card' : 'Add New Card' }}</h5>
            <button type="button" class="close" @click="closeModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="handleSave">
              <div class="form-group">
                <label>Coin ID</label>
                <!-- <input v-model="formData.id_coin" type="text" class="form-control" required /> -->
                <select v-model="formData.id_coin" class="form-control" required>
                  <option v-for="coin in coins" :key="coin._id" :value="coin._id">
                    {{ coin.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label>Quantity</label>
                <input v-model="formData.quantity" type="number" class="form-control" step="0.01" required />
              </div>
              <div class="form-group">
                <label>Number of Wins</label>
                <input v-model="formData.numberWins" type="number" class="form-control" required />
              </div>
              <div class="form-group">
                <label>Remaining</label>
                <input v-model="formData.remaining" type="number" class="form-control" required />
              </div>
              <div class="form-group">
                <label>Upload Images</label>
                <input type="file" class="form-control" multiple @change="handleImageUpload" />

              </div>



              <div class="form-group">
                <label>Is Winner</label>
                <select v-model="formData.isWinner" class="form-control">
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </div>
              <button type="submit" class="btn btn-primary">{{ isEditing ? 'Update' : 'Create' }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axiosInstance from '@/utils/axios';

const cards = ref([]);
const coins = ref([]);
const showModal = ref(false);
const isEditing = ref(false);
const currentCardId = ref(null);

const imageFiles = ref([]);
const filterStatus = ref('all'); 
const formData = ref({
  id_coin: '',
  quantity: 0,
  numberWins: 0,
  remaining: 0,
  isWinner: false,
});


const formatNumber = (value) => {
  return value !== undefined && value !== null ? value : 0;
};

const handleImageUpload = (event) => {
  const files = event.target.files;
  if (files && files.length > 0) {
    imageFiles.value = Array.from(files);
  }
};

const fetchCards = async () => {
  try {

    const response = await axiosInstance.get('/api/all/card');
    cards.value = response.data.data;


    const responsecoin = await axiosInstance.get('/api/all/coin');
    coins.value = responsecoin.data.data;
    console.log(responsecoin)

  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
   

const openModal = (mode, card = null) => {
  showModal.value = true;
  if (mode === 'edit' && card) {
    isEditing.value = true;
    currentCardId.value = card._id;
    formData.value = {
      id_coin: card.id_coin?._id || '',
      quantity: card.quantity || 0,
      numberWins: card.numberWins || 0,
      remaining: card.remaining || 0,
      isWinner: card.isWinner || false,
    };
  } else {
    isEditing.value = false;
    resetForm();
  }
};


// Filter the cards based on "Is Winner" status
const filteredCards = computed(() => {
  if (filterStatus.value === 'yes') {
    return cards.value.filter(card => card.isWinner);
  } else if (filterStatus.value === 'no') {
    return cards.value.filter(card => !card.isWinner);
  }
  return cards.value;  
});
const closeModal = () => {
  showModal.value = false;
  resetForm();
};



const handleSave = async () => {
  try {

    const cardData = new FormData();
    cardData.append('id_coin', formData.value.id_coin);
    cardData.append('quantity', formData.value.quantity);
    cardData.append('numberWins', formData.value.numberWins);
    cardData.append('remaining', formData.value.remaining);
    cardData.append('isWinner', formData.value.isWinner);


    if (imageFiles.value && imageFiles.value.length > 0) {
      imageFiles.value.forEach((file) => {
        cardData.append('image', file);
      });
    }

    if (isEditing.value) {
      await axiosInstance.put(`/api/update/card/${currentCardId.value}`, cardData);
    } else {
      await axiosInstance.post('/api/save/card', cardData);
    }

    fetchCards();
    closeModal();
  } catch (error) {
    console.error('Error saving card config:', error.response ? error.response.data : error.message);
  }
};


const deleteCard = async (cardId) => {
  try {
    await axiosInstance.delete(`/api/delete/card/${cardId}`);
    fetchCards();
  } catch (error) {
    console.error('Error deleting card config:', error);
  }
};


const resetForm = () => {
  formData.value = {
    id_coin: '',
    quantity: 0,
    numberWins: 0,
    remaining: 0,
    isWinner: false,
  };
  imageFiles.value = []; currentCardId.value = null;
  isEditing.value = false;
};


onMounted(fetchCards);
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal .modal-dialog {
  margin: 10% auto;
}
</style>
