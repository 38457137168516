<template>
  <div class="card">
    <div class="card-header pb-0">
      <h4>Total Withdraws/Pages: {{ data.length }}</h4>
      <p>* Export file by select tag: For example select Ton then will export ton *</p>
      <div class="d-flex justify-content-around">
        <select v-model="filterType" @change="applyFilter" class="filter-select btn me-2">
          <option value="">Filter by Coin</option>
          <option value="ton">TON</option>
          <!-- <option value="TONONE">TONONE</option> -->
          <option value="jUSDT">USDT</option>
        </select>
        <button @click="handleExportExcel" class="btn-export btn btn-sm">Export to Excel</button> 
        <button @click="clearSessionStorageAndReload" class="btn-export btn btn-sm">ReLoad</button>
             <button v-if="selectedRecords.length > 0" @click="handleBulkUpdate" class="btn btn-primary btn-sm ">Update Selected Withdraws</button>

      </div>

      <modal v-if="isModalOpen" @close="isModalOpen = false">
        <template #title>
          {{ editingRecord ? 'Edit Withdraw' : 'Add Withdraw' }}
        </template>
        <template #content>
          <form @submit.prevent="handleSave">
            <div class="form-group">
              <label>Username</label>
              <input type="text" v-model="form.username" disabled />
            </div>
            <div class="form-group">
              <label>Amount</label>
              <input type="number" v-model="form.value" disabled />
            </div>
            <div class="form-group">
              <label>Wallet Address</label>
              <input type="text" v-model="form.wallet_address" disabled />
            </div>
            <div class="form-group">
              <label>Status</label>
              <select v-model="form.status">
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div class="form-actions">
              <button type="submit">Save</button>
              <button type="button" @click="isModalOpen = false">Cancel</button>
            </div>
          </form>
        </template>
      </modal>
    </div>
    <div v-if="notification.message" class="alert"
      :class="notification.type === 'success' ? 'alert-success' : 'alert-danger'">
      {{ notification.message }}
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center test-start">
          <thead>
            <tr>
             
               <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7  text-center"> <input type="checkbox" @click="selectAll($event)"></th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name/Address</th>
            
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Amount</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total Reward
                TON
                Ref</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total Reward
                TONONE Ref</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Day
              </th>

            </tr>
          </thead>
          <tbody class="text-start">
            <tr v-for="record in paginatedData" :key="record._id">
               <td class="  text-center ">
                <input type="checkbox" :value="record" v-model="selectedRecords">
              </td>
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-start">
                    <div class="text-xs font-weight-bold mb-0 py-3">
                      <h6 > Username: {{ record.userId?.username }} </h6>
                      <h6>First name:{{ record.userId?.first_name }}</h6>
                      <h6>Address Wallet:</h6>
                      <h6 >{{ record.wallet_address }}</h6>
                    </div>
                    <!-- <h6 class="mb-0 text-sm">{{ record.userId?._id }}</h6> -->
                  </div>
                </div>
              </td>
           
              <td class="align-middle text-center text-sm">
                <div class="text-xs font-weight-bold mb-0 py-3"> {{ getAmountText(record.value) }} | {{ record.coinName
                  ?
                  record.coinName.toLowerCase() : 'Unknown' }}
                  <img :src="record.coinImage" alt="Coin Image" style="width: 40px; height: 40px;">
                </div>

                <button v-if="!record.status" @click="handleWithdraw(record, record.coinName)"
                  class="btn-withdraw btn btn-sm btn-primary me-2" :disabled="record.isLoading">
                  <span v-if="record.isLoading" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                  <span v-else>Withdraw</span>
                </button>
                <button v-if="!record.status" @click="handleUpdate(record, getAmountText(record.value))"
                  class="btn-withdraw btn btn-sm btn-primary" :disabled="record.isLoading">
                  <span v-if="record.isLoading" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                  <span v-else>Update Withdraw</span>
                </button>

              </td>
              <td class="align-middle text-center">
                <button class="btn btn-sm" :style="{ color: record.status ? 'green' : 'red' }">
                  {{ record.status ? 'Success' : 'Pending approval' }}

                </button>
              </td>
              <td class="text-xs font-weight-bold mb-0 text-center">
                {{ record.userId?.totalRewardRef != null ? formatNumber(record.userId.totalRewardRef) : '0' }}
              </td>
              <td class="text-xs font-weight-bold mb-0 text-center">
                {{ record.userId?.totalRewardToken != null ? formatNumber(record.userId.totalRewardToken) : '0' }}
              </td>

              <td class="text-xs font-weight-bold mb-0 text-center">

                Created At {{ formatDate(record.createdAt) }}<br>
                Updated At {{ formatDate(record.updatedAt) }}

              </td>

            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination-controls d-flex justify-content-center p-2">
        <button class="btn btn-sm " @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button class="btn btn-sm" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
        <h6>Total Withdraws: {{ totalItems }}</h6>

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axiosInstance from '@/utils/axios';
import * as XLSX from 'xlsx';
// import qs from 'qs';
const data = ref([]);
const filteredData = ref([]);
const loading = ref(false);
const isModalOpen = ref(false);
const editingRecord = ref(null);
const filterType = ref('');
const notification = ref({ type: '', message: '' });
const currentPage = ref(1);
const itemsPerPage = ref(10);
const totalPages = ref(1);
const totalItems = ref(0);

const selectedRecords = ref([]);
const form = ref({
  username: '',
  value: 0,
  wallet_address: '',
  status: '',
  valueWithdraw:0,
});
const paginatedData = computed(() => {
    return filteredData.value;
});

// const totalPages = computed(() => {
//   return Math.ceil(filteredData.value.length / itemsPerPage.value);
// });

const nextPage = () => {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
        fetchWithdraws(currentPage.value);  
    }
};

const prevPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
        fetchWithdraws(currentPage.value);   
    }
};
const fetchWithdraws = async (page = 1) => {
  loading.value = true;
  try {
    // No need to check session storage here since we want to fetch fresh data each time
    const params = {
      page: page,
      limit: itemsPerPage.value,
    };
    if (filterType.value) {
      params.coinName = filterType.value;
    }
    
    const response = await axiosInstance.get('/api/pagination/withdraw', { params });
    console.log(response, "API Response");

    const withdraws = response.data.data;
    data.value = withdraws; // Store the current page data
    filteredData.value = data.value; // Update the filtered data
    totalPages.value = response.data.totalPages; 
    totalItems.value = response.data.totalItems;
    console.log(withdraws, "Data for current page");

    const withdrawsWithCoin = await Promise.all(withdraws.map(async (withdraw) => {
      if (withdraw.coin) {
        try {
          const coinResponse = await axiosInstance.get(`/api/coin/${withdraw.coin._id}`);
          withdraw.coinName = coinResponse.data.data.name;
          withdraw.coinImage = coinResponse.data.data.image[0];
          console.log("Coin Name:", withdraw.coinName);
        } catch (coinError) {
          console.error(`Failed to fetch coin details for coin ID ${withdraw.coin}:`, coinError);
          withdraw.coinName = 'Unknown';
        }
      } else {
        withdraw.coinName = 'Unknown';
      }
      return withdraw;
    }));

    data.value = withdrawsWithCoin.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    filteredData.value = data.value; // Ensure this is updated after sorting

  } catch (error) {
    console.error('Failed to fetch withdraws', error);
  } finally {
    loading.value = false;
  }
};

// const fetchWithdraws = async (page = 1) => {
//   loading.value = true;
//   try {
    
//     const storedData = sessionStorage.getItem('withdraws');
//     if (storedData) {
//       data.value = JSON.parse(storedData);
//       filteredData.value = data.value;
//     } else {
//  const params = {
//         // page: currentPage.value,
//         page: page,
//         limit: itemsPerPage.value,
//       };
//        if (filterType.value) {
//         params.coinName = filterType.value;
//       }
//  const response = await axiosInstance.get('/api/pagination/withdraw', { params });
//  console.log(response,"padw")
 

//       console.log(response, "user");

//       const withdraws = response.data.data;
//   data.value = withdraws; // Store the current page data
//         filteredData.value = data.value; // Update the filtered data
//         totalPages.value = response.data.totalPages; // Update the total pages from API
// console.log(withdraws,"data")
//       const withdrawsWithCoin = await Promise.all(withdraws.map(async (withdraw) => {
//         if (withdraw.coin) {
//           try {
//             const coinResponse = await axiosInstance.get(`/api/coin/${withdraw.coin._id}`);
//             withdraw.coinName = coinResponse.data.data.name;
//             withdraw.coinImage = coinResponse.data.data.image[0];

//             console.log("Coin Name:", withdraw.coinName);
//           } catch (coinError) {
//             console.error(`Failed to fetch coin details for coin ID ${withdraw.coin}:`, coinError);
//             withdraw.coinName = 'Unknown';
//           }
//         } else {
//           withdraw.coinName = 'Unknown';
//         }
//         return withdraw;
//       }));

//        data.value = withdrawsWithCoin.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
//       filteredData.value = data.value;
//         totalPages.value = response.data.totalPages; 
//       sessionStorage.setItem('withdraws', JSON.stringify(data.value));
//     }
//   } catch (error) {
//     console.error('Failed to fetch withdraws', error);
//   } finally {
//     loading.value = false;
//   }
// };
const clearSessionStorageAndReload = () => {
  currentPage.value = 1;
  fetchWithdraws(currentPage.value);  
};
 
 
const applyFilter = () => {
  currentPage.value = 1;
  console.log('Filtering by:', filterType.value);
  fetchWithdraws(currentPage.value);
};
const selectAll = (event) => {
  if (event.target.checked) {
    selectedRecords.value = [...paginatedData.value];  
  } else {
    selectedRecords.value = [];  
  }
};

const handleBulkUpdate = async () => {
  try {
    await Promise.all(selectedRecords.value.map(record => handleUpdate(record)));
    selectedRecords.value = [];  
    fetchWithdraws(true);  
  } catch (error) {
    console.error('Failed to update records', error);
  }
};

// const paginatedData = computed(() => {
//   const startIndex = (currentPage.value - 1) * itemsPerPage.value;
//   const endIndex = startIndex + itemsPerPage.value;
//   return filteredData.value.slice(startIndex, endIndex);
// });

const handleSave = async () => {
  try {
    if (editingRecord.value) {
      await axiosInstance.put(`/api/update/withdraw/${editingRecord.value._id}`, form.value);
      console.log('Withdraw updated successfully');
    } else {
      await axiosInstance.post('/api/save/withdraw/token', form.value);
      console.log('Withdraw added successfully');
    }
    isModalOpen.value = false;
  
  fetchWithdraws(true);  
  } catch (error) {
    console.error('Failed to save withdraw');
      
  fetchWithdraws(true);  

  }
};
const test = Math.floor(10.1 * 10000) / 10000;
console.log(test);   



const handleWithdraw = async (record, amountType) => {
  record.isLoading = true;

const valueWithdraws = Math.floor((record.value) * 10000) / 10000;
console.log(valueWithdraws,"sssss")
const lowerAmountType = amountType.toLowerCase(); 


  try {
    // const apiUrl = amountType.includes('TONONE')
    //   ? '/api/save/withdraw/token'
    //   : '/api/transfer/ton';
const apiUrl = lowerAmountType.includes('tonone')

  ? '/api/save/withdraw/token'
  : lowerAmountType.includes('jusdt')
  ? '/api/transfer/jusdt'
  : '/api/transfer/ton';
console.log(apiUrl, "API URL"); 
    const payload = {
      value: record.value,
      userId: record.userId._id,
      to: record.wallet_address,
      valueWithdraw:valueWithdraws,
      status: true,
      _id: record._id
    };

    console.log(payload, "payload");

    // const response = await axiosInstance.post(apiUrl, qs.stringify(payload), {
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //   }
    // });
const response = await axiosInstance.post(apiUrl, payload, {
  headers: {
    'Content-Type': 'application/json'
  }
});
// const response = await axiosInstance.post(apiUrl, qs.stringify(payload), {
//   headers: {
//     'Content-Type': 'application/x-www-form-urlencoded'
//   }
// });

    console.log(response, "rut");

    if (response.data.success) {
        sessionStorage.removeItem('withdraws');
      notification.value = { type: 'success', message: 'Withdraw request submitted successfully' };
      record.status = true;
      setTimeout(() => {
        notification.value = { type: '', message: '' };
      }, 3000);
     
  fetchWithdraws(true);  
    } else {
      notification.value = { type: 'error', message: 'Failed to submit withdraw request' };
          
  fetchWithdraws(true);  

    }
  } catch (error) {
    notification.value = { type: 'error', message: 'An error occurred while processing the withdraw request' };
    console.error('An error occurred while processing the withdraw request', error);
      
  fetchWithdraws(true);  

  } finally {

    record.isLoading = false;
  }
};
 const handleUpdate = async (record) => {
  record.isLoading = true;
const value = parseFloat(record.value);
console.log(value,"ádas");
const valueWithdraws = Math.floor((record.value) * 10000) / 10000;

  try {
    const response = await axiosInstance.post('/api/update/transaction', {
      status: true,
      userId: record.userId._id,
      _id: record._id,
         valueWithdraw:valueWithdraws,
    });
console.log(record._id,"id")
console.log(record.userId._id,"id")

    if (response.data.success) {
      alert('Transaction updated successfully!');
        sessionStorage.removeItem('withdraws');
     
  fetchWithdraws(true);  

    } else {
      alert('Failed to update the transaction.');

            
  fetchWithdraws(true);  

    }
  } catch (error) {
    console.error('Error updating transaction:', error);
    alert('An error occurred while updating the transaction.');
      sessionStorage.removeItem('withdraws');
         
  fetchWithdraws(true);  

  }
};

 
const handleExportExcel = () => {
  const exportData = filteredData.value.map(record => {
    const amount = `${record.value?.toFixed(1) || '0.0'} ${record.coinName || 'Unknown'}`;

    return {
      Username: record.userId?.username,
      'First Name': record.userId?.first_name,
      'Wallet Address': record.wallet_address,
      Amount: amount,
      Status: record.status ? 'Active' : 'Inactive',
      'Total Reward TON Ref': record.userId.totalRewardRef !== null ? `${record.userId.totalRewardRef.toFixed(1)} TON` : '0',
      'Total Reward TONONE Ref': record.userId.totalRewardToken !== null ? `${record.userId.totalRewardToken.toFixed(1)} TONONE` : '0',
      'Created At': new Date(record.createdAt).toLocaleString(),
      'Updated At': new Date(record.updatedAt).toLocaleString(),
    };
  });

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Withdraws');
  XLSX.writeFile(workbook, 'Withdraws.xlsx');
};

const getAmountText = (value) => {
  if (value === null || value === undefined) return '0,00';


  const formattedValue = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  return formattedValue;
};

const formatNumber = (value) => {
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const formatDate = (date) => {
  return new Date(date).toLocaleString();
};

// onMounted(fetchWithdraws);
onMounted(() => {
  fetchWithdraws(currentPage.value);
});
</script>

<style scoped>
.modal {
  display: block;
}

.form-group {
  margin-bottom: 16px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls button {
  margin: 0 10px;
}
</style>
