<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex justify-content-between">
        <h6>Rate Configs Table </h6>
        <h6> <span> Count: {{ cardsNumber.count || "0" }} 💨💨💨 ALL Lucky Wheel: {{ cardsNumber.number || "0" }}</span>
        </h6>
      </div>

      <button @click="openModal('add')" class="btn btn-primary mb-3">Add New Config</button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">STT</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">ID Lottery</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Quantity</th>

              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">COIN</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Numerical</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rate, index) in rates" :key="rate._id">
              <td>{{ index + 1 }}</td>
              <td>{{ rate.id_lottery?._id }}</td>
              <td>{{ formatNumber(rate.id_lottery?.quantity) }}</td>

              <td>{{ getCoinName(rate.id_lottery.id_coin) }}
                <img :src="getCoinImage(rate.id_lottery?.id_coin)" alt="Coin Image" style="width: 30px; height: 30px;">
              </td>
              <td>{{ rate.numerical.join(' | ') }}</td>
              <td>
                <button @click="openModal('edit', rate)" class="btn btn-warning me-2 d-none">Edit</button>
                <button @click="deleteCard(rate._id)" class="btn btn-danger d-none">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="showModal" class="modal show" tabindex="-1" role="dialog"
      style="display: block; background-color: rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isEditing ? 'Edit Config' : 'Add New Config' }}</h5>
            <button type="button" class="close" @click="closeModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="handleSave">
              <div v-for="(entry, index) in rateEntries" :key="index" class="form-group">
                <label>Lottery ID</label>
                <select v-model="entry.id_lottery" class="form-control" required>
                  <option v-for="lottery in lotteries" :key="lottery._id" :value="lottery._id">{{ lottery._id }}
                  </option>
                </select>
                <label>Numerical</label>
                <input v-model="entry.numerical" type="text" class="form-control" placeholder="e.g. 1,2,3" required />
                <button type="button" @click="removeRateEntry(index)" class="btn btn-danger mt-2"
                  v-if="rateEntries.length > 1">Remove</button>
              </div>
              <div class="d-flex flex-column justify-content-around">
                <button type="button" @click="addRateEntry" class="btn btn-secondary mt-3">Add Rate Entry</button>
                <button type="submit" class="btn btn-primary mt-3">{{ isEditing ? 'Update' : 'Create' }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axiosInstance from '@/utils/axios';
// State
const lotteries = ref([]);
const cards = ref([]);
const rates = ref([]);
const coins = ref([]);
const showModal = ref(false);
const isEditing = ref(false);
const currentCardId = ref(null);
const rateEntries = ref([{ id_lottery: '', numerical: '' }]);
const cardsNumber = ref({ count: 0, number: 0 });

// Format numbers for display
const formatNumber = (value) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value);
};

// Fetch rates and related data
const fetchCards = async () => {
  try {
    const response = await axiosInstance.get('/api/rate/all');
    console.log(response, "test")
    rates.value = response.data?.data[0]?.rates;
    cards.value = response.data?.data;
    cardsNumber.value = {
      count: response.data?.data[0]?.count || 0,
      number: response.data?.data[0]?.number || 0,
    }
    const responsecoin = await axiosInstance.get('/api/all/coin');
    coins.value = responsecoin.data?.data;
  } catch (error) {
    console.error('Error fetching card configs:', error);
  }
};

const fetchLotteries = async () => {
  try {
    const response = await axiosInstance.get('/api/lottery/all');
    lotteries.value = response.data.data;
  } catch (error) {
    console.error('Error fetching lotteries:', error);
  }
};

// Open modal for adding or editing a rate entry
const openModal = (mode, rate = null) => {
  showModal.value = true;
  if (mode === 'edit' && rate) {
    isEditing.value = true;
    currentCardId.value = rate._id;
    rateEntries.value = rate.rates?.map(r => ({
      id_lottery: r.id_lottery?._id,
      numerical: r.numerical.join(','),
    }));
  } else {
    isEditing.value = false;
    resetForm();
  }
};

// Get coin name by id
const getCoinName = (id_coin) => {
  const coin = coins.value.find(c => c._id === id_coin);
  return coin ? coin.name : 'Unknown';
};

// Get coin image by id
const getCoinImage = (id_coin) => {
  const coin = coins.value.find(c => c._id === id_coin);
  return coin && coin.image && coin.image[0] ? coin.image[0] : '';
};

// Close modal and reset form
const closeModal = () => {
  showModal.value = false;
  resetForm();
};

// Save the rate entries (create or update)
const handleSave = async () => {
  try {
    const ratesData = rateEntries.value.map(entry => ({
      id_lottery: entry.id_lottery,
      numerical: entry.numerical.split(',').map(num => num.trim()),
    }));

    if (isEditing.value) {
      await axiosInstance.put(`/api/update/rate/${currentCardId.value}`, { rates: ratesData });
    } else {
      await axiosInstance.post('/api/save/rate', { rates: ratesData });
    }

    closeModal();
    fetchCards(); // Reload data after save
  } catch (error) {
    console.error('Error saving rate config:', error.response ? error.response.data : error.message);
  }
};

// Add a new rate entry in the modal
const addRateEntry = () => {
  rateEntries.value.push({ id_lottery: '', numerical: '' });
};

// Remove a rate entry
const removeRateEntry = (index) => {
  if (rateEntries.value.length > 1) {
    rateEntries.value.splice(index, 1);
  }
};

// Delete a rate entry
const deleteCard = async (cardId) => {
  try {
    await axiosInstance.delete(`/api/delete/buy/config/${cardId}`);
    fetchCards(); // Reload data after deletion
  } catch (error) {
    console.error('Error deleting card config:', error);
  }
};

// Reset form to initial state
const resetForm = () => {
  rateEntries.value = [{ id_lottery: '', numerical: '' }];
};

// Fetch data on component mount
onMounted(() => {
  fetchCards();

  fetchLotteries();
});
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal .modal-dialog {
  margin: 10% auto;
}
</style>
